#navBarContainer {
    height: 100vh;
    width: 150px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    background: #070B0F;
    left: 0;
    top: 0;
    border-radius: 0 20px 20px 0;
}

#linksContainer {
    width: 100%;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    margin-top: 100px;
    cursor: pointer;
}

.linkActive {
    display: block;
    background: linear-gradient(to left, rgba(255, 255, 255, 0) 33%, rgba(255, 255, 255, 0.1) 100%);
}
.navBarLink {
    width: 150px;
    height: 40px;
    color: white;
    text-decoration: none;
    display: flex;
    flex-direction:row;
    justify-content: center;
    align-items: center;
    -webkit-tap-highlight-color: transparent;
}