#homepageContainer {
    height: 100vh;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

#profilePicture {
    height: 200px;
    border-radius: 200px;
    border: solid white 2px;
}

.homepageContentContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 75%;
}