#projectsContainer {
    height: 100vh;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

#projectsContentContainer {
    width: 75%;
}

.projectsLink {
    color: white;
    font-size: 20px;
}